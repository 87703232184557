import React, { useState } from 'react';
import Image from 'next/image';
import SectionHeading from '../Common/SectionHeading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

const TabSection = ({ title=null, shortDescription=null }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:my-8 my-4">
                <p className='text-center mb-0 mt-0'>What is PharmDelve?</p>
                <SectionHeading title={title} shortDescription={shortDescription}/>
                <ul className="w-6/12 mx-auto tabs text-center px-0 list-none flex md:flex-row flex-col xl:flex-wrap xl:justify-between xl:-ms-flex-wrap-wrap mb-30 bg-opacity-25 bg-blue-200 active">
                    {/* Tab 1 */}
                    <li
                        className={
                            activeTab === 0
                                ? 'text-md text-white current text-center flex list-none justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap bg-gradient-to-tr from-blue-600 to-blue-200 hover:bg-gradient-to-r hover:from-blue-500 hover:via-blue-400 hover:to-blue-300 transition-transform duration-500'
                                : 'text-md text-black text-center flex list-none transition-transform duration-500 justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap'
                        }
                        onClick={() => handleTabClick(0)}
                    >
                        <span className={activeTab === 0 ? 'text-white flex mt-0 flex-col justify-center' : 'flex mt-0 flex-col justify-center'}>Market Insights</span>
                    </li>

                    {/* Tab 2 */}
                    <li
                        className={
                            activeTab === 1
                                ? 'text-md text-white current text-center flex list-none justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap bg-gradient-to-tr from-blue-600 to-blue-200 hover:bg-gradient-to-r hover:from-blue-500 hover:via-blue-400 hover:to-blue-300 transition-transform duration-500'
                                : 'text-md text-black text-center flex list-none transition-transform duration-500 justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap'
                        }
                        onClick={() => handleTabClick(1)}
                    >
                        <span className={activeTab === 1 ? 'text-white flex mt-0 flex-col justify-center' : 'flex mt-0 flex-col justify-center'}>Pipeline Insights</span>
                    </li>
                    {/* Tab 3 */}
                    <li
                        className={
                            activeTab === 2
                                ? 'text-md text-white current text-center flex list-none justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap bg-gradient-to-tr from-blue-600 to-blue-200 hover:bg-gradient-to-r hover:from-blue-500 hover:via-blue-400 hover:to-blue-300 transition-transform duration-500'
                                : 'text-md text-black text-center flex list-none transition-transform duration-500 justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap'
                        }
                        onClick={() => handleTabClick(2)}
                    >
                        <span className={activeTab === 2 ? 'text-white flex mt-0 flex-col justify-center' : 'flex mt-0 flex-col justify-center'}>Epidemiology</span>
                    </li>
                    {/* Tab 4 */}
                    <li
                        className={
                            activeTab === 3
                                ? 'text-md text-white current text-center flex list-none justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap bg-gradient-to-tr from-blue-600 to-blue-200 hover:bg-gradient-to-r hover:from-blue-500 hover:via-blue-400 hover:to-blue-300 transition-transform duration-500'
                                : 'text-md text-black text-center flex list-none transition-transform duration-500 justify-center items-center px-4 py-2 cursor-pointer xl:whitespace-nowrap'
                        }
                        onClick={() => handleTabClick(3)}
                    >
                        <span className={activeTab === 3 ? 'text-white flex mt-0 flex-col justify-center' : 'flex mt-0 flex-col justify-center'}>Drug Insights</span>
                    </li>

                </ul>

                <div className="tabContent">
                    {/* Tab 1 Content */}
                    <div className={`tabItem ${activeTab === 0 ? '' : 'hidden'}`}>
                        <div className="">
                            <div className="mt-5">
                                <h4 className="text-xl font-semibold text-[#1b5df3]">Market Insights Reports</h4>
                                <p className="">A comprehensive market research report includes an in-depth analysis of a specific therapy area&apos;s overall assessment in terms of market shares, epidemiology, market drivers and barriers, and forecasted projections on market share and sizing.</p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                                <ul>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Patient-based Market Forecasts
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Major Drugs Uptake
                                    </li>
                                </ul>
                                <ul>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Current Treatment Practices
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        10 Years Market Forecast
                                    </li>
                                </ul>
                                <ul>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Impact of Upcoming Therapies
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Key Competitors
                                    </li>
                                </ul>
                            </div>
                            <button className='btn mt-7'><Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}}>Know More</Link></button>
                        </div>
                    </div>

                    {/* Tab 2 Content */}
                    <div className={`tabItem ${activeTab === 1 ? '' : 'hidden'}`}>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="md:col-span-6 col-span-12 mt-5">
                                <h4 className="font-semibold text-xl text-[#1b5df3]">Pipeline Insights Reports</h4>
                                <p className="">Provides a detailed profile of all clinical and non-clinical assets, as well as information on deals, collaborations, licensing, and patents. It also includes therapeutic evaluations for all active drugs based on molecule type, route of administration, monotherapies, and combination therapies.</p>
                                <button className='btn'><Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}}>Know More</Link></button>
                            </div>
                            <div className="md:col-span-6 col-span-12 mt-5">
                                <h4 className="font-semibold text-xl mb-4 text-[#1b5df3]">Key Takeaways</h4>
                                <ul>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Pipeline Product Profiles
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Therapeutic Assessment
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Impact of Drugs
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Unmet Needs
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Tab 3 Content */}
                    <div className={`tabItem ${activeTab === 2 ? '' : 'hidden'}`}>
                        <div className="">
                            <div className="mt-5">
                                <h4 className="text-xl font-semibold text-[#1b5df3]">Epidemiology Insights Reports</h4>
                                <p className="">Provides a structure for epidemiological research and understanding of various indications, including prevalence/incidence and distribution in various geographies, clinical manifestations, etiology, pathophysiology, severity, diagnosis, and therapies.</p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                                <ul>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        10-year Forecast
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Patient Segmentation
                                    </li>
                                </ul>
                                <ul>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Factors Driving Growth
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Disease Risk & Burden
                                    </li>
                                </ul>
                                <ul>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Risk of Disease by Segmentation
                                    </li>
                                    <li className="flex">
                                        <div className="pr-4">
                                            <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                        </div>
                                        Unmet Needs
                                    </li>
                                </ul>
                            </div>
                            <button className='btn mt-7'><Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}}>Know More</Link></button>
                        </div>
                    </div>

                    {/* Tab 4 Content */}
                    <div className={`tabItem ${activeTab === 3 ? '' : 'hidden'}`}>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="md:col-span-6 col-span-12 mt-5">
                                <h4 className="font-semibold text-xl text-[#1b5df3]">Drug Insights Reports</h4>
                                <p className="">Provides comprehensive information about the drug, including the MoA, safety and efficacy data, patent information, and market exclusivity data. It also includes information on the drug&apos;s R&D activities, including regulatory milestones, other developmental activities, forecasted sales, and future market assessment.</p>
                                <button className='btn'><Link href="https://pharmdelve.delveinsight.com/register" style={{color: 'white'}}>Know More</Link></button>
                            </div>
                            <div className="md:col-span-6 col-span-12 mt-5">
                                <div>
                                    <h4 className="font-semibold text-xl mb-4 text-[#1b5df3]">Key Takeaways</h4>
                                    <ul>
                                        <li className="flex">
                                            <div className="pr-4">
                                                <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                            </div>
                                            Forecasted Market Outlook
                                        </li>
                                        <li className="flex">
                                            <div className="pr-4">
                                                <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                            </div>
                                            Key Cross Competition
                                        </li>
                                        <li className="flex">
                                            <div className="pr-4">
                                                <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                            </div>
                                            Therapeutic Portfolio
                                        </li>
                                        <li className="flex">
                                            <div className="pr-4">
                                                <FontAwesomeIcon className='text-[#1b5df3]'icon={faCheck}/>
                                            </div>
                                            Current Developmental Scenario
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Add more tab contents as needed */}
                </div>
            </div>
        </>
    );
};

export default TabSection;